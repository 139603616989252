<template>
  <div class="hello">
    <div class='backgroundBottom'></div>

    <div class="desc">
      {{ charName }}님이 누울 돗자리를<br>골라 보세요
    </div>
    <div>
      <splide 
        :options="splideOptions"
        @splide:moved="onChanged">
        <splide-slide
          class="mb-5"
          v-for="image of images" 
          :key="image">
          <div class='back-border' :class="currActive(image)">
            <img class="d-block w-100" :src="image.url" :alt="image.alt">
          </div>
        </splide-slide>
      </splide>
    </div>

    <div>
    
    </div>
    <div class="fixed-bottom next-button" @click="nextPage">
      다음
    </div>
  </div>
</template>

<script>
import { data } from '../data'

export default {
  name: 'SelectBackgroundView',
  data: () => {
    return {
      images: data.image,
      currIndex: 0,
      charName: localStorage.charName,

      splideOptions: {
        gap    : '15px',
        arrows : false,
        padding: {
          right: '5rem',
          left : '5rem',
        },
      },
    }
  },
  mounted: function () {
    console.log('mounted')
  },
  methods: {
    currActive: function (image) {
      return image.index == this.currIndex ? 'active' : ''
    },
    onChanged: function (_, newIndex) {
      this.currIndex = newIndex
    },
    nextPage: function () {
      localStorage.selectBackground = this.currIndex;
      
      this.$router.push('/select-object')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  background: #DCDE49;
}

.backgroundBottom {
  position: fixed;
  width: 100%;
  height: 50%;
  background: #DCDE49;
  z-index: -10;
  bottom: 0;
}

.next-button {
  text-align: center;
  padding: 16px;

  margin: 16px;
  font-size: 16px;
  font-weight: bold;
  background: #000;
  color: #fff;
  border-radius: 8px;
  height: 56px;
}

.desc {
  padding-top: 66px;
  padding-bottom: 32px;

  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

.back-border {
  width: 100%;
  height: 250px;
  border: 3px solid #3Ab54B;
  border-radius: 8px;
  background: #3Ab54B;
      
  vertical-align: middle;
  display: table-cell;
}

.back-border.active {
  border: 3px solid #000;
}


</style>
