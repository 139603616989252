import { createWebHistory, createRouter } from "vue-router";

import MainView from "@/views/MainView.vue";
import SelectCharView from "@/views/SelectCharView.vue";
import SelectBackgroundView from "@/views/SelectBackgroundView.vue";
import SelectObjectView from "@/views/SelectObjectView.vue";
import ArchiveView from "@/views/ArchiveView.vue";
import MyPageView from "@/views/MyPageView.vue";

const routes = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
  },
  {
    path: "/select-char",
    name: "SelectCharView",
    component: SelectCharView,
  },
  {
    path: "/select-background",
    name: "SelectBackgroundView",
    component: SelectBackgroundView,
  },
  {
    path: "/select-object",
    name: "SelectObjectView",
    component: SelectObjectView,
  },
  {
    path: "/archive",
    name: "ArchiveView",
    component: ArchiveView,
  },
  {
    path: "/my-page",
    name: "MyPageView",
    component: MyPageView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;