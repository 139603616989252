<template>
  <div id='hello' class="hello">
    <div class="sticky-top" style="top: -4px">
      <lottie-player :src="lottie"
                     background="transparent"
                     speed="1"
                     style="max-width: 100%; height: auto; margin-bottom: -10px"
                     loop
                     autoplay>
      </lottie-player>
    </div>
    <div class="count-div">
      {{ count }}명이 드러 누웠어요<br> 
    </div>
    <div  class="result-div container">
      <div id="test" class="row" style="background: #3Ab54B">  <!-- row-cols-3 row-cols-xs-2 -->
        <div class="result-item col-6 col-sm-4" v-for="result of resultList" :key="result.id">
          <div class="item-wrap" :class="myItem(result.id)">
            <img :src="result.imageData" :alt="myAlt(result.id)"/>
            <div class="item-name" v-if="myItem(result.id)">
              {{ result.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex fixed-bottom m-3">
      <div class="flex-shrink-1" style="margin-right: 8px;" @click="share">
        <div class="d-flex justify-content-center" style="width: 56px; height:56px; background-color:black;  border-radius: 8px; align-items:center;">
          <div>
            <img src="../assets/share_icon.png" style="">
          </div>  
        </div>
      </div>
      <div class="w-100">
         <div v-if="make" class="next-button" @click="gotoMyPage"> 
          내 이미지 저장하기
        </div>
        <div v-else class="next-button" @click="gotoHome">
          처음부터 다시하기
        </div>
      </div>
    </div>

    <div id="toast" class="position-fixed start-50 bottom-0 center p-0 translate-middle" style="z-index: 5">
      <div class="toast hide align-items-center center w-100 text-white border-0" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            링크가 복사 되었습니다
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>
   
    <section v-if="showModal">
      <PopupModal :closeBtn="false"
                  @before-close="beforeCloseModal">
      </PopupModal>
    </section>
  </div>
</template>

<script>
import { firestore } from '../main'
import { Toast } from 'bootstrap'
import PopupModal from './components/PopupModal'

export default {
  name: 'ArchiveView',
  components: {
    PopupModal
  },
  data: () => {
    return {
      count: 0,
      resultList: [],
      lottie: require('../assets/animation.json'),
      make: false,
      readCount: 9,
      loading: false,
      toastItem: null,
      showModal: true,
    }
  },
  mounted: async function () {
    this.make = this.$route.query.make ? true : false
    // console.log('mounted', this.make)

    const snapshot = await firestore
                           .collection('test')
                           .orderBy('timestamp', 'desc')
                           .limit(this.readCount)
                           .get()

    if (snapshot.empty == false) {
      snapshot.forEach(doc => {
        const data = doc.data()
        // console.log('data', data)
        this.resultList.push({
          id: doc.id,
          ...data
        })
      }) 
      // this.count = this.resultList.length
    }
    await this.loadCount()

    window.addEventListener('scroll', this.handleScroll);

    const toastElList = [].slice.call(document.querySelectorAll('.toast'))
    const toastList = toastElList.map(function (toastEl) {
      return new Toast(toastEl, {delay: 2000})
    })

    this.toastItem = toastList[0]

    this.showModal = false
  },
  unmounted: function () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: async function () {
      // console.log(window.scrollY, window.innerHeight, document.body.clientHeight)
      
      if (window.scrollY + window.innerHeight > document.body.clientHeight - 20)
      {
          // console.log('scrolled');
          await this.loadMore();
          console.log('loadMore', this.resultList.length)
      }
    },
    loadCount: async function () {
      const doc = await firestore
                            .collection('test_count')
                            .doc('count')
                            .get();

      if (doc.exists) {
        this.count = doc.data().count
      }
    },
    loadMore: async function () {
      if (this.loading) {
        return
      }

      this.loading = true

      let lastItem = this.resultList[this.resultList.length-1];

      const snapshot = await firestore
                        .collection('test')
                        .orderBy('timestamp', 'desc')
                        .startAfter(lastItem.timestamp)
                        .limit(this.readCount)
                        .get()

      if (snapshot.empty == false) {
        snapshot.forEach(doc => {
          const data = doc.data()
          this.resultList.push({
            id: doc.id,
            ...data
          })
        }) 
      }

      this.loading = false
    },
    myItem: function (key) {
      return key == localStorage.myId ? 'my' : ''
    },
    myAlt: function (key) {
      if (key == localStorage.myId) {
        return "내가 누워 있는 모습이 보이고 있어요"
      } else {
        return "다른 사람들이 누워 있는 모습이 보이고 있어요"
      }
    },
    gotoMyPage: function () {
      this.$router.push('/my-page')
    },
    gotoHome: function () {
      this.$router.push('/')
    },
    share: async function () {
      let share_url = 'https://yanoopja.newways.kr'
      const shareText = '날씨도 좋은데 같이 드러 누울 사람?\n #야눕자 #여의어때 #뉴웨이즈 #선젊포고'
      const shareData = {
        title: shareText,
        text: shareText,
        url: share_url,
      }

      if (navigator.share) {
        await navigator.share(shareData)
      } else {
        var tempElem = document.createElement('textarea');
        tempElem.value = share_url;
        document.body.appendChild(tempElem);

        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);

        this.toastItem.show();
        // console.log('this.toastItem.show();')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.video {
  width: 100%;
  display: flex;
}

.next-button {
  text-align: center;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  background: #000;
  color: #fff;
  border-radius: 8px;
  height: 56px;
}

.count-div {
  background: #000;
  padding: 12px 0px;
  font-size: 16px;
  text-align: center;

  color: #FFFFFF;
}
.result-item {
  padding: 0px;
}

.result-item img{
  -webkit-touch-callout: none;
  user-select: none;
  width: 100%;
}

.my {
  border: 4px solid #E79A37;
}

.item-wrap {
  position: relative;
}

.item-name {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #E79A37;
  font-size: 14px;
  color: #000;
  font-weight: bold;
  padding: 6px 12px;
}

</style>
