<template>
  <div class="hello">
    <div class="desc">
      국회의사당 장소 태그<br>점령하러 가기!
    </div>

    <div class="step bg-white" style="border-bottom: 1px solid black;">
      <div class="share-desc px-4">
        <div>1. 내가 누운 모습을 저장한다</div>
        <div>2. 내 SNS피드에 업로드 한다 (인스타그램 추천!)</div>
        <div>3. 위치를 '국회의사당'으로 지정한다</div>
        <div>4. 해시태그를 복사해서 함께 올리면</div>
        <div>5. 국회의사당 앞에 드러 눕기 완료!</div>
      </div>
    </div>

    <div class="save-image-desc">
      이미지를 꾹 눌러 저장하세요
    </div>
    <div class="">
      <div class="my-char">
        <img :src="imageData" alt="내가 누워 있는 모습이 보이고 있어요"/>
      </div>      
    </div>

    <div class="mx-3" style="margin-top: 40px; margin-bottom: 32px;">
      <div class="row">
        <div class="hash-text ms-3 col border border-dark">
          #야눕자 #여의어때 #선젊포고 #뉴웨이즈
        </div>
        <div class="copy-btn me-3 col-3 btn-dark d-flex justify-content-center" style="background-color: black;">
          <div style="align-self: center" @click="copyText">
            <div class="align-middle text-white fw-bold">
              복사하기
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="why-button" @click="gotoNewways">
      왜 드러 누웠대요?
    </div>
    <div class="archive-button" @click="gotoArchive">
      누워 있는 사람들 보기
    </div>

    <div class="space">
    </div>
    
    <div class="bg-white">
      <div class="share-text">
        친구에게 돗자리 부대 뉴스 공유하기
      </div>
      <div class="share-icon">
        <div class="share-button" @click="share">
          공유하기
        </div>
      </div>
      <div class="logo">
        <img src="../assets/images/logo3.png" alt="뉴웨이즈 로고"/>
      </div>
    </div>

    <div class="real-footer">
      <div class="fw-bold text-white">
        만든 사람
      </div>
      <div class="creator">
        <div class="">
          <a href="https://newways.kr/?utm_source=campaign&utm_medium=footer&utm_campaign=noop&utm_content=credit">기획: 뉴웨이즈</a>
        </div>
        <div class="">
          <a href="http://instagram.com/sandwichpress.kr">캐릭터 디자인: 주혜린</a>
        </div>
        <div class="">
          <a href="https://www.facebook.com/HyunjinYoonMe">UI/UX 디자인: 윤현진</a>
        </div>
        <div class="">
          <a href="https://www.facebook.com/lee.yongsang">페이지 개발: 이용상</a>
        </div>
        <div class="">
          <a href="https://newt.news/">데이터 분석: 이찬우</a>
        </div>
      </div>
      <div class="support">
        <!-- 이 웹사이트는 청년허브의 &lt;청년업&gt; 사업 지원을 받았습니다 -->
      </div>
    </div>

    <div class="position-fixed start-50 bottom-0 center p-0 translate-middle" style="z-index: 5">
      <div class="toast hide align-items-center center w-100 text-white border-0" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            링크가 복사 되었습니다
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>

    <div class="position-fixed start-50 bottom-0 center p-0 translate-middle" style="z-index: 5">
      <div class="toast hide align-items-center center w-100 text-white border-0" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            해시태그가 복사 되었습니다
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'bootstrap'

export default {
  name: 'ArchiveView',
  data: () => {
    return {
      imageData: localStorage.imageData,
      toastItem: null,
      hashToastItem: null,
    }
  },
  mounted: async function () {
    const toastElList = [].slice.call(document.querySelectorAll('.toast'))
    const toastList = toastElList.map(function (toastEl) {
      return new Toast(toastEl, {delay: 2000})
    })

    this.toastItem = toastList[0]
    this.hashToastItem = toastList[1]

    console.log('mounted')
  },
  methods: {
    gotoNewways: function () {
      // location.href = "https://newways.kr/noop"
      window.open("https://newways.kr/noop", '_blank');
    },
    gotoArchive: function () {
      this.$router.push('/archive')
    },
    copyText: function () {
      var tempElem = document.createElement('textarea');
      tempElem.value = "#야눕자 #여의어때 #선젊포고 #뉴웨이즈";  
      document.body.appendChild(tempElem);

      tempElem.select();
      document.execCommand("copy");
      document.body.removeChild(tempElem);

      this.hashToastItem.show();
    },
    share: async function () {
      let share_url = 'https://yanoopja.newways.kr'
      const shareText = '날씨도 좋은데 같이 드러 누울 사람?\n #야눕자 #여의어때 #뉴웨이즈 #선젊포고'
      const shareData = {
        title: shareText,
        text: shareText,
        url: share_url,
      }

      if (navigator.share) {
        await navigator.share(shareData)
      } else {
        var tempElem = document.createElement('textarea');
        tempElem.value = share_url;
        document.body.appendChild(tempElem);

        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);

        this.toastItem.show();
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  color: #000;
}

.hello {
  background: #E5E5E5;
}

.desc {
  padding-top: 40px;
  padding-bottom: 40px;

  font-weight: bold;
  font-size: 30px;
  line-height: 46px;
  text-align: center;
  background: #000000;
  color: #fff;
}

.save-image-desc {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  color: #616161;
  margin-top: 40px;
  margin-bottom: 8px;
}

.share-desc {
  font-size: 16px;
  line-height: 24px;
  padding: 40px 0px;
  font-weight: bold;
}

.why-button {
  text-align: center;
  padding: 16px;

  margin: 16px 16px 8px 16px;
  font-size: 16px;
  font-weight: bold;
  background: #000;
  color: #fff;
  border-radius: 8px;
  height: 56px;
}

.archive-button {
  text-align: center;
  padding: 16px;

  margin: 0px 16px;
  font-size: 16px;
  font-weight: bold;
  background: #E5E5E5;
  color: #000;
  border: solid 1px #000;
  border-radius: 8px;
  height: 56px;
}

.share-button {
  text-align: center;
  padding: 16px;

  margin: 0px 16px 32px 16px;
  
  font-size: 16px;
  font-weight: bold;
  background: #fff;
  color: #000;
  border: solid 1px #000;
  border-radius: 8px;
  height: 56px;
}

.my-char {
  margin: 0px 56px;
}

.my-char img{
  width: 100%;
}

.space {
  padding: 31px 0px;
  background: #E5E5E5;
}

.hash-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  padding: 12px;
}

.copy-btn {
  text-align: center;
}

.share-text {
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 14px;
  text-align: center;
  color: #000;
}

.logo {
  text-align: center;
  padding-bottom: 50px;
}

.logo img {
  width: 7rem;
}

.real-footer {
  background-color: #000;
  color: #EEEEEE;
  padding: 32px 16px;
  font-size: 14px;
}

.real-footer .support {
  font-size: 13px;
  color: #b1b1b1;
}

.creator {
  margin: 16px 0px 8px 0px;
}

.creator a {
  color: #EEEEEE;
  text-decoration: underline;
  line-height: 24px;
}

</style>
