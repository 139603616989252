<template>
  <div>
    <transition name="fade">
      <div class="overlay" v-if="modalVisible" @click.self="toggleModal(); beforeClose()">
        <button class="close" v-if="closeBtn" @click.stop="toggleModal(); beforeClose()">
          <i class="fa fa-close"></i>
        </button>
        <div class="modal" v-if="modalVisible">
          <div class="d-flex notice">
            <div>
              <div class="notice-img">
                <img :src="imageUrl">
              </div>
              <div class="notice-text">
                돗자리 펼치는 중
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script scoped>
import { data } from '../../data'

export default {
  data() {
    return {
      modalVisible: false,
      index: 0,
      imageUrl: data.objectForSelect[0].url,
    };
  },
  name: "PopupModal",
  components: {
  },
  props: {
    btnText: String,
    modalContent: String,
    closeBtn: Boolean,
    showNav: Boolean,
  },
  mounted() {
    this.toggleModal()

    setInterval(function () {
      this.index++;
      this.index = this.index % data.objectForSelect.length
      this.imageUrl = data.objectForSelect[this.index].url
    }.bind(this), 800)
  },
  methods: {
    beforeOpen() {
      this.$emit("before-open");
    },
    beforeClose() {
      console.log('beforeClose')
      this.$emit("before-close");
    },
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    },
  }
};
</script>

<style scoped>
.notice {
  justify-content: center;
  height: 100%;
  align-items: center;
}

.notice-img {
  width: 200px;
}

.notice-text {
  color: #fff;
  font-weight: bold;
  margin-bottom: 40px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  position: fixed;
  float: right;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
}
.close {
  background-color: rgb(0, 0, 0);
  font-size: 42px;
  border: 0px;
  text-shadow: none;
  position: fixed;
  z-index: 10000;
  display: block;
  top: 16px;
  right: 16px;
  opacity: 0.4;
  color: white;
}
.content {
  background-color: rgb(255, 255, 255);
}
.modal {
  display: block;
  text-align: center;
}
/* //Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
