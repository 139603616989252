import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Firebase from 'firebase'
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import 'vue-blu/dist/css/vue-blu.min.css'

const firebaseConfig = {
    apiKey: "AIzaSyCzrl5xpLCu5TiOC-YzdeVBsN_n3JD-lUY",
    authDomain: "noop-newways.firebaseapp.com",
    projectId: "noop-newways",
    storageBucket: "noop-newways.appspot.com",
    messagingSenderId: "812467061482",
    appId: "1:812467061482:web:fb4a2957e7ef9d7e84cd93",
    measurementId: "G-J01EWCWKNQ"
};

let firebase = Firebase.initializeApp(firebaseConfig);
export const fireauth = firebase.auth();
export const firestore = firebase.firestore();
export const firestorage = firebase.storage();


createApp(App)
    .use(router)
    .use(VueSplide)
    .mount('#app')
