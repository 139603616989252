<template>
  <div class="hello">
    <div class='backgroundBottom'></div>
    
    <div class="desc">
      {{ charName }}님의 누울 자리 옆에는?
    </div>
    <div>
      <splide 
        :options="splideOptions"
        @splide:moved="onChanged">
        <splide-slide
          class="mb-5"
          v-for="image of images" 
          :key="image">
          <div class='char-border' :class="currActive(image)">
            <img class="d-block w-100" :src="image.url" :alt="image.alt">
          </div>
        </splide-slide>
      </splide>
    </div>

    <div class="fixed-bottom next-button" @click="nextPage">
      지금 바로 드러눕기
    </div>

    <div style="display: none;">
      <img :src="imageData" style="width:100%"/>
    </div>
    <div style="display: none;">
      <canvas id="canvas" ></canvas>
    </div>

    <section v-if="showModal">
      <PopupModal :closeBtn="false"
                  @before-close="beforeCloseModal">
      </PopupModal>
    </section>
  </div>
</template>

<script>
/*eslint no-undef: "off"*/
import { firestore } from '../main'
import { data } from '../data'
import moment from 'moment'
import PopupModal from './components/PopupModal'

export default {
  name: 'SelectObjectView',
  components: {
    PopupModal
  },
  data: () => {
    return {
      images: data.objectForSelect,
      currIndex: 0,
      charName: localStorage.charName,
      showModal: false,

      splideOptions: {
        gap    : '15px',
        arrows : false,
        padding: {
          right: '5rem',
          left : '5rem',
        },
      },

      canvas: null,
      imageData: null,
    }
  },
  mounted: function () {
    // console.log('mounted', moment().unix())
  },
  methods: {
    currActive: function (image) {
      return image.index == this.currIndex ? 'active' : ''
    },
    onChanged: function (_, newIndex) {
      this.currIndex = newIndex
    },
    createImage: async function () {
      const imageSize = 2084;
      const canvasSize = 300;
      this.canvas = new fabric.Canvas('canvas', {
          isDrawingMode: false,
          width: canvasSize,
          height: canvasSize,
          allowTouchScrolling: false,
      });

      const backIndex = +localStorage.selectBackground
      const charIndex = +localStorage.selectChar
      const objectIndex = +localStorage.selectObject

      fabric.Image.fromURL(data.image[backIndex].url, (back) => {
          back.scaleX = canvasSize / imageSize
          back.scaleY = canvasSize / imageSize
          back.left = 0
          back.top = 0
          this.canvas.add(back);
          console.log('finish back')

          fabric.Image.fromURL(data.char[charIndex].url, (char) => {
              char.scaleX = canvasSize / imageSize
              char.scaleY = canvasSize / imageSize
              char.left = 0
              char.top = 0
              this.canvas.add(char);
              console.log('finish char')

              fabric.Image.fromURL(data.object[objectIndex].url, (ob) => {
                  ob.scaleX = (canvasSize / imageSize)
                  ob.scaleY = (canvasSize / imageSize)
                  ob.left = 0
                  ob.top = 0
                  this.canvas.add(ob);
                  console.log('finish ob')
              })
          })
      })

      return new Promise(resolve => setTimeout(() => {
        const data = this.canvas.toDataURL({
            format: 'png',
            multiplier: 4,
        })
        resolve(data)
      }, 1000));
    },
    saveData: async function () {
      this.showModal = true

      const imageData = await this.createImage();
      this.imageData = imageData;

      localStorage.imageData = imageData;

      const res = await firestore.collection('test').add({
        imageData: imageData,
        background: localStorage.selectBackground,
        char: localStorage.selectChar,
        name: localStorage.charName,
        obejct: localStorage.selectObject,
        timestamp: moment().unix()
      })

      console.log('saveData', res)
      localStorage.myId = res.id;

      const doc = await firestore
                      .collection('test_count')
                      .doc('count')
                      .get();

      const count = doc.data().count + 1
      await firestore.collection('test_count').doc('count').set({count});
    },
    nextPage: async function () {
      localStorage.selectObject = this.currIndex;
      
      await this.saveData()

      this.$router.push('/archive?make=true')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  background: #DCDE49;
}

.backgroundBottom {
  position: fixed;
  width: 100%;
  height: 50%;
  background: #DCDE49;
  z-index: -10;
  bottom: 0;
}

.next-button {
  text-align: center;
  padding: 16px;

  margin: 16px;
  font-size: 16px;
  font-weight: bold;
  background: #000;
  color: #fff;
  border-radius: 8px;
  height: 56px;
}

.char-border {
  width: 100%;
  height: 250px;
  border: 0px solid #000;
  border-radius: 8px;
  background: #fff;
      
  vertical-align: middle;
  display: table-cell;
}

.char-border.active {
  border: 3px solid #000;
}


.desc {
  padding-top: 84px;
  padding-bottom: 32px;

  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

.char-border img{
  transform: rotate(0deg);
}
</style>
