<template>
  <div class="hello">
    <div class='backgroundBottom'></div>

    <div class="desc">
      어떤 모습으로 눕고 싶으세요?
    </div>
    <div>
      <splide 
        :options="splideOptions"
        @splide:moved="onChanged">
        <splide-slide
          class="mb-5"
          v-for="image of images" 
          :key="image">
          <div class='char-border' :class="currActive(image)">
            <img class="d-block w-100" :src="image.url" :alt="image.alt">
          </div>
        </splide-slide>
      </splide>
    </div>
    <div class="name mx-5">
      <input type="text" v-model="charName" placeholder="이름 또는 닉네임 쓰기">
      <div class="error" v-if="error != ''">
        {{ error }}
      </div>
    </div>
    
    <div class="fixed-bottom next-button" @click="nextPage">
      다음
    </div>
  </div>
</template>

<script>
import { data } from '../data'

export default {
  name: 'SelectCharView',
  data: () => {
    return {
      images: data.char,
      currIndex: 0,
      charName: '',
      error: '',

      splideOptions: {
        gap    : '15px',
        arrows : false,
        // width : '100%',
        // fixedwidth : '6rem',
        // autoWidth : true,
        padding: {
          right: '5rem',
          left : '5rem',
        },
      },
    }
  },
  mounted: function () {
    console.log('mounted')
  },
  methods: {
    currActive: function (image) {
      return image.index == this.currIndex ? 'active' : ''
    },
    onChanged: function (_, newIndex) {
      this.currIndex = newIndex
    },
    nextPage: function () {
      this.error = ''

      const nameLen = this.charName.length
      if (nameLen > 10) {
        this.error = "10자 이내로 입력해 주세요"
        return;
      }

      if (nameLen < 1) {
        this.error = "이름 또는 닉네임을 입력해주세요"
        return;
      }

      localStorage.selectChar = this.currIndex;
      localStorage.charName = this.charName;
      this.$router.push('/select-background')
    }
  }
  // this.$route.params.username
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  background: #DCDE49;
}

.backgroundBottom {
  position: fixed;
  width: 100%;
  height: 50%;
  background: #DCDE49;
  z-index: -10;
  left: 0;
  bottom: 0;
}

.name {
  text-align: center;
}

.name input {
  margin-top: 24px;
  margin-bottom: 4px;
  padding: 0px 16px;
  width: 100%;
  height: 48px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 0px;
}

.error {
  text-align: left;
  color: #D52D26;
}

.next-button {
  text-align: center;
  padding: 16px;

  margin: 16px;
  font-size: 16px;
  font-weight: bold;
  background: #000;
  color: #fff;
  border-radius: 8px;
  height: 56px;
}

.desc {
  padding-top: 47px;
  padding-bottom: 32px;

  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

.char-border {
  width: 100%;
  height: 250px;
  border: 0px solid #000;
  border-radius: 8px;
  background: #fff;
      
  vertical-align: middle;
  display: table-cell;
}

.char-border.active {
  border: 3px solid #000;
}

.char-border img{
  transform: rotate(0deg);
}

</style>
