export const data = {
    "image": [
        {'index':0, 'url': require('./assets/images/back_00.png'), 'alt': '체크 무늬 돗자리에 "30대 대통령 왜 않돼?" 메시지'},
        {'index':1, 'url': require('./assets/images/back_01.png'), 'alt': '요가 매트 위에 "젊치인이 오면 깨워주세요" 메시지'},
        {'index':2, 'url': require('./assets/images/back_02.png'), 'alt': '대나무 돗자리 위에 "정치의 얼굴을 다양하게" 메시지'},
        {'index':3, 'url': require('./assets/images/back_03.png'), 'alt': '잔디 위에 "#야눕자 #여의어때" 메시지'},
    ],
    "char": [
        {'index':0, 'url': require('./assets/images/char_00.png'), 'alt': '해변가에서 일광욕'},
        {'index':1, 'url': require('./assets/images/char_01.png'), 'alt': '유튜브로 최애 영상 보기'},
        {'index':2, 'url': require('./assets/images/char_02.png'), 'alt': '어려운 요가 자세하기'},
        {'index':3, 'url': require('./assets/images/char_03.png'), 'alt': '이불에 돌돌 말려 잠들기'},
        {'index':4, 'url': require('./assets/images/char_04.png'), 'alt': '엎드려 책 보기'},
        {'index':5, 'url': require('./assets/images/char_05.png'), 'alt': '완전히 이완하기'},
        {'index':6, 'url': require('./assets/images/char_06.png'), 'alt': '투탕카멘 되기'},
    ],
    "object": [
        {'index':0, 'url': require('./assets/images/object_00.png'), 'alt': '도시락과 맥주를 두고 싶어요'},
        {'index':1, 'url': require('./assets/images/object_01.png'), 'alt': '강아지와 같이 있고 싶어요'},
        {'index':2, 'url': require('./assets/images/object_02.png'), 'alt': '고양이와 같이 있고 싶어요'},
        {'index':3, 'url': require('./assets/images/object_03.png'), 'alt': '투표 용지를 두고 싶어요'},
        {'index':4, 'url': require('./assets/images/object_04.png'), 'alt': '농구공을 두고 싶어요'},
        {'index':5, 'url': require('./assets/images/object_05.png'), 'alt': '축구공을 두고 싶어요'},
        {'index':6, 'url': require('./assets/images/object_06.png'), 'alt': '촛불을 두고 싶어요'},
    ],
    "objectForSelect": [
        {'index':0, 'url': require('./assets/images/select_object_00.png'), 'alt': '도시락과 맥주를 두고 싶어요'},
        {'index':1, 'url': require('./assets/images/select_object_01.png'), 'alt': '강아지와 같이 있고 싶어요'},
        {'index':2, 'url': require('./assets/images/select_object_02.png'), 'alt': '고양이와 같이 있고 싶어요'},
        {'index':3, 'url': require('./assets/images/select_object_03.png'), 'alt': '투표 용지를 두고 싶어요'},
        {'index':4, 'url': require('./assets/images/select_object_04.png'), 'alt': '농구공을 두고 싶어요'},
        {'index':5, 'url': require('./assets/images/select_object_05.png'), 'alt': '축구공을 두고 싶어요'},
        {'index':6, 'url': require('./assets/images/select_object_06.png'), 'alt': '촛불을 두고 싶어요'},
    ] 
}