<template>
  <div class="hello">
    <div>
      <div class="story">
        <img src="../assets/images/main_01.png" alt="여의도 국회의사당 앞, 돗자리 부대가 속출하고 있다는 뉴스 화면이 보인다. 뉴스 속보입니다. 여의도 국회의사당 앞에 드러 눕는 돗자리 부대가 속출하고 있습니다. 누워 있는 모습도 제각각인데요. 왜 여기에 누워 계신 건지 물어 봤습니다. 참가자의 말입니다. 누울 자리 보고 발 뻗으라는데 도무지 누울 자리가 없어서요. 날씨도 좋은데 같이 누워 보실래요?">
      </div>
    </div>
    <div class="wrap-button">
      <div class="main-next-button" @click="nextPage">
        나도 같이 드러눕기
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainView',
  methods: {
    nextPage: function () {
      this.$router.push('/select-char')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


.hello {
  background: #EFEFEF;
}

.wrap-button {
  background: #EFEFEF;
  padding: 0px 0px 32px 0px;
}

.main-next-button {
  text-align: center;
  padding: 16px;

  margin: 0px 16px;
  font-size: 16px;
  font-weight: bold;
  background: #000;
  color: #fff;
  border-radius: 8px;
  height: 56px;
}

.story img{
  width: 100%;
  margin-bottom: 0px;
  background: #EFEFEF;
}

/* .story img.last{
  padding-bottom: 100px;
} */
</style>
